import {ChangeDetectionStrategy, Component, DestroyRef} from '@angular/core'
import {Dialogistic} from '../dialogistic.interface'
import {Observable, Subject} from 'rxjs'
import {FormControl, Validators} from '@angular/forms'
import {takeUntilDestroyed} from '@angular/core/rxjs-interop'

@Component({
  templateUrl: './contact-support-dialog.component.html',
  styles: `
    :host {
      @apply block
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactSupportDialogComponent implements Dialogistic<Props, ContactSupportOutput> {
  props!: Props
  readonly message = new FormControl<string | null>(null, [Validators.required])
  readonly sendMessageLoading$ = new Subject<boolean>()

  private output = new Subject<ContactSupportOutput>()

  constructor(private readonly destroyRef: DestroyRef) {}

  send() {
    if (this.message.value) {
      this.sendMessageLoading$.next(true)
      this.props
        .sendMessageFn(this.message.value)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(result => {
          this.output.next({message: result.message})
          this.message.reset()
          this.sendMessageLoading$.next(false)
        })
    }
    this.message.markAsDirty()
  }

  close(): void {
    this.output.next({message: undefined})
  }

  reply$() {
    return this.output.asObservable()
  }

  setData(props: Props): void {
    this.props = props
  }
}

export interface Props {
  sendMessageFn: SendMessageFn
}

export interface ContactSupportOutput {
  message?: string
}

export type SendMessageFn = (message: string) => Observable<{
  message: string
}>
