import {NgModule} from '@angular/core'
import {DialogComponent} from './dialog.component'
import {SuccessDialogComponent} from './success-dialog/success-dialog.component'
import {FeedbackDialogComponent} from './feedback-dialog/feedback-dialog.component'
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component'
import {RadioModule} from '../forms/radio/radio.module'
import {ReactiveFormsModule} from '@angular/forms'
import {FormsModule} from '../forms/forms.module'
import {I18nDirective} from '../i18n/i18n.directive'
import {DialogModule as CdkDialogModule} from '@angular/cdk/dialog'
import {FaIconComponent} from '@fortawesome/angular-fontawesome'
import {AsyncPipe, NgClass} from '@angular/common'
import {ContactSupportDialogComponent} from './contact-support-dialog/contact-support-dialog.component'
import {ButtonModule} from '../button/button.module'
import {DialogContentComponent} from './content/dialog-content.component'
import {DialogFooterComponent} from './footer/dialog-footer.component'

@NgModule({
  declarations: [
    DialogComponent,
    DialogContentComponent,
    DialogFooterComponent,
    SuccessDialogComponent,
    FeedbackDialogComponent,
    ConfirmationDialogComponent,
    ContactSupportDialogComponent,
  ],
  exports: [
    DialogComponent,
    DialogContentComponent,
    DialogFooterComponent,
    SuccessDialogComponent,
    FeedbackDialogComponent,
    ConfirmationDialogComponent,
    ContactSupportDialogComponent,
  ],
  imports: [
    RadioModule,
    ReactiveFormsModule,
    FormsModule,
    I18nDirective,
    CdkDialogModule,
    FaIconComponent,
    NgClass,
    AsyncPipe,
    ButtonModule,
  ],
})
export class DialogModule {}
