<cft-dialog [title]="t('feedbackDialog.title')" *cftI18n="let t">
  <cft-dialog-content>
    <div class="flex justify-center">
      <div class="flex flex-col space-x-md pb-2xl sm:flex-row">
        <div>
          <fa-icon [icon]="faSuccess" size="3x" class="text-success"></fa-icon>
        </div>
        <div class="mt-xl sm:mt-0">
          <h4>{{ data.title }}</h4>
          <p>{{ data.message }}</p>
        </div>
      </div>
    </div>
  </cft-dialog-content>
</cft-dialog>
