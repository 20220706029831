<cft-dialog *cftI18n="let t" [title]="data.title || t('confirmationDialog.title')" data-testid="confirmation-dialog">
  <cft-dialog-content>
    <h3 class="heading-base">{{ data.message }}</h3>
    @if (data.tip) {
      <p class="mt-xs text-sm font-normal">{{ data.tip }}</p>
    }
  </cft-dialog-content>
  <cft-dialog-footer>
    <button type="button" class="btn btn-sm btn-secondary" (click)="confirm.next(false)">{{ t('no') }}</button>
    <button type="button" class="btn btn-sm btn-hero ml-sm" (click)="confirm.next(true)">{{ t('yes') }}</button>
  </cft-dialog-footer>
</cft-dialog>
