<cft-dialog [title]="t('contactSupportDialog.title')" *cftI18n="let t" data-testid="contact-support-dialog">
  <cft-dialog-content>
    <p>
      {{ t('contactSupportDialog.description') }}
    </p>
    <div class="mt-xs h-24">
      <cft-form-field class="h-full" [autogrow]="true">
        <label for="supportMessage">{{ t('contactSupportDialog.messageLabel') }}</label>
        <textarea id="supportMessage" [formControl]="message"></textarea>
      </cft-form-field>
    </div>
  </cft-dialog-content>
  <cft-dialog-footer>
    <button type="button" class="btn btn-secondary-vermilion" (click)="close()">
      {{ t('contactSupportDialog.cancel') }}
    </button>
    <button
      type="button"
      class="btn btn-vermilion"
      (click)="send()"
      cft-button
      [isLoading$]="sendMessageLoading$"
      [disabled]="message.invalid"
    >
      {{ t('contactSupportDialog.send') }}
    </button>
  </cft-dialog-footer>
</cft-dialog>
